.citations-section{
    border: 1px solid #c3bdbd;
    padding: 12px;
    border-radius: 3px;
}
.scroll-section{
    padding: 30px 10px;
    height: 200px;
    overflow-y: scroll;
    white-space: pre-line;
}
.videolib-link ul{
    margin: 0 !important;
    padding: 0 !important;
}
.videolib-link ul li {
    list-style: none;
    display: inline-block;
    text-align: left;
    padding:10px 18px;
    /* font-weight: 600; */
    cursor: pointer;
    font-family: "Montserrat-Medium";
    /* color: #00698A; */
}
.videolib-link ul li:hover{
    font-weight: 600 ;
}

.videolib-link ul li{
    color: #00698A;
}

li.active{
    color: #00698A;
    text-decoration: none;
    font-weight: 600;
}
