.container-section{
    margin-bottom: 2rem;
    margin-top: 6rem;
}
.home-title{
    font-size: 24px;
    text-align: left;
    color: #00698A;
    font-family: "Montserrat-SemiBold";
}
.home-subtitle{
    font-size: 28px;
    font-family: "Montserrat-Medium";
    color: #000;
    text-align: left;
}
.title-content{
    text-align: left;
}
.chat-section{
    border: 1px solid #DEE2E6;
    border-radius: 5px;
    padding-bottom: 1rem;
}
.chat-title{
    font-family: "Montserrat-SemiBold";
    font-size: 24px;
    text-align: left;
    padding: 0 1rem;
}
.dash-icon{
    padding: 0 1em;
    text-align: right;
}
.title-author{
    font-family: "Montserrat-SemiBold";
    display: flex;
}
.video-section{
    text-align: left;
    position: relative;
}
.like-section{
    margin-left: auto;
    margin-right: 1rem;
    text-align: right;
}
.video-img{
    background:url("./assets/Video.png") no-repeat;
    background-size: cover;
    width: 100%;
    height: 495px;
}
.socialicon-btn{
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    padding: 10px 20px;
    width: 120px;
    height: 44px;
    background: #EDFAFE;
    border-radius: 30px;
    color: #000000;
    border: none;
}
.author-name{
    position: relative;
    top: 5px;
    text-align: left;
}
.profile-img{
    background: url("./assets/chris-francy1.png") no-repeat;
    width: 48px;
    height: 48px;
    background-size: cover;
    display: inline-block;
    border-radius: 25px;    
}
.socialicon-btn:hover{
    background: #00C3EE;
}
.chat-content{
    text-align: left;
    padding: 0 1.5rem;
    font-family: "Montserrat-Medium";
    font-size: 18px;
}
.chat-tag{
    padding: 10px 20px;
    background: #88C4F0;
    border-radius: 20px;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    text-align: center;
    color: #000000;
    display: inline-block;
}
.chat-tag-sction{
    text-align: left;
    margin-left: 3rem;
}
.type-input{
    padding: 0.5rem;
}
.video-tag-section{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}
.tag-title{
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    padding-top:22px;
}
.video-tag{
    padding: 10px 20px;
    background: #E4F2EF;
    border-radius: 20px;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    text-align: center;
    color: #000000;
    margin-left: 18px;
    margin-top:12px;
}
.promo-section{
    margin: 3rem 0 3rem 0;
}
.breadcrumb-disabled a{
    pointer-events: none !important;
    color: rgba(17, 17, 17, 0.5)
}
.breadcrumb-active a{
    color: #17A2B8 !important;
}
.newrelease-section{
    margin-top: 1rem;
}
.newrelease-content{
    text-align: left;
    background: #00698A;
    color: #fff;
    padding: 2.2rem 2rem;
}
.newrelease-title{
    font-size: 2em;
}
.newrelease-desc{
    font-size: 24px;
    padding-top: 1rem;
}
.newrelease-duration{
    padding-top: 8px;
}
.comingsoon-section{
    position: relative;
    margin-top: 1rem;
}
.comingsoon-title{
    font-size: 2.5rem;
    position: absolute;
    top: 10%;
    left: 10%;
    background: #E4F2EF;
    padding: 8px 16px;
    font-weight: 600;
}
.comingsoon-desc{
    font-size: 2rem;
    position: absolute;
    bottom: 10%;
    left: 10%;
    background: #E4F2EF;
    padding: 8px 16px;
    text-align: left;
    line-height: 42px;
}
.embed-responsive{
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}
.embed-responsive .embed-responsive-item{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 5px;
}
.shareform-label{
    padding-top: 10px;
    font-weight: 600;
}
input:read-only{
    background: #f9f9f9;
    border: 1px solid #afafaf !important;
    color: #101010 !important;
}
.sharemodal-section{
    position: relative;
}
.load-spinner{
    display: flex;
    flex-direction: column;
    position: absolute;
    background: rgb(0 0 0/ 70%);
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    z-index: 10;
    color: #fff;
}
.suceess-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.socialicon-btn.clicked {
    background: #00C3EE;
}
