.footer-section{
    background: #EDFAFE;
    text-align: left;
    padding-bottom: 1.5rem;
}
.contact-title{
    font-size: 24px;
    margin-top: 1em;
}
.form-control{
    border: 1px solid #000 !important;
    padding: 0.65rem 0.75rem !important;
}
.submit-btn{
    padding: 10px 20px;
    width: 180px;
    height: 44px;
    background: #00C3EE;
    border-radius: 10px;
    color: #212529;
    border: none;
    font-weight: 600;
}
.submit-btn-loading{
    padding: 10px 25px;
    width: 169px;
    height: 44px;
    background: rgb(8 146 177 / 84%);
    border-radius: 10px;
    color: #212529;
    border: none;
    font-weight: 600;
}
.copyright-content{
    font-size: 14px;
    color:#212529;
}
.supplemental-text{
    font-size: 14px;
    color: #6C757D;
}
.footer-list{
    color:#00698A;
    font-size: 16px;
    font-weight: 600;
}
.follow-us{
    font-family: "Montserrat-Medium";
    font-size: 18px;
    color:#000
}
.icon-section{
    margin-right: 3rem;
    cursor: pointer;
}
@media (min-width: 992px){
    .col-align{
        margin-left: 12rem;
    }
}
@media (max-width: 992px){
    .col-align{
        margin-left: auto;
    }
}

@media (max-width: 640px){
    .submit-btn{
       width: 175px;
       font-size: 14px !important;
    }
}

.contact-success-message {
    display: flex;
    align-items: center;
    /* background-color: #ffffff; */
    padding: 6px;
    border-radius: 5px;
    /* margin-top: 10px; */
    color: #07851B;
    font-weight: 600;
    justify-content: left;
    font-size: 14px;
    margin-top:20px;
}

