.recommend-tabs .nav-tabs .nav-link.active{
    border: none ;
    background: transparent;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
    color: #000 !important;
    border-bottom: 3px solid #00C3EE;
}
.recommend-tabs .nav-tabs .nav-link{
    color: #000 !important;
    border: none ;
    padding-bottom: 15px !important;
}
.recommend-tabs .nav-tabs{
    border-bottom: 3px solid #DFDFDF;
}
.recommend-tabs .react-multiple-carousel__arrow--right{
    right: calc(0% + 1px);
}
.recommend-tabs .react-multiple-carousel__arrow--left{
    left: calc(0% + 1px);
}
.recommend-tabs .react-multiple-carousel__arrow{
    min-width : 25px;
    min-height: 25px;
    border-radius: 2px;
    background:rgba(0,0,0,0.8)
}
.citation-content-section{
    text-align: left;
    height: 200px;
    overflow-y: scroll;
    padding: 0 50px;
    white-space: pre-line;
}
.recvideo-duration {
    /* transform : translate(-104px) */
}
.recvideo-title{
    font-weight: 600;
    /* transform : translate(-52px) */
}
.playicon-section{
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width:70px;
    height: 70px;
    background:url(./assets/playicon.svg) no-repeat;

}