.nav-background{
    background: #00698A;
}
.header-section .nav-link{
    color:#fff !important;
    margin:17px 48px !important;
    font-family: "Montserrat-SemiBold";

}
.dropdown-toggle::after{
    display: none !important; 
}
.header-section .navbar-toggler, .navbar-toggler:hover{
    border: none !important;
}

.header-section .dropdown-menu.show{
    background: #EDFAFE !important;
    border: none;
    border-radius: 0;
    border-top:5px solid #00C3EE;
}

.header-section > .dropdown-menu.show{
    height: 530px;
    overflow-y: scroll;
}

@media (min-width: 992px){
    .full-width .dropdown-menu.show {
        position: fixed !important;
        left: 0;
        top: 88px;
        right: 0;
        width: 100vw;
        border-top:5px solid #00C3EE;
        background: #EDFAFE !important;
    }
}

@media (max-width: 1199px){
    .header-section .nav-link{
        margin:17px 24px !important
    }
    .navbar-brand{
        margin-right: 0 !important;
    }
}

@media (max-width: 991px){
    .full-width .dropdown-menu.show {
       min-height: 400px;
       height: 400px;
       overflow-y: scroll;
    }
}

.header-section .menu-title{
    font-family: "Montserrat-Medium";
    font-size: 18px;
    color: #343A40 !important;
    white-space: pre-wrap;
    /* border-bottom: 1px solid #343A40; */
}

.header-section .dropdown-divider{
    margin: 2px 16px;
    border-top: 1px solid #343A40;
}

.header-section .menu-item{
    font-family: "Montserrat-Medium";
    color: #00698A;
}

.header-section .dropdown-item:hover{
    background: transparent;
    font-weight: 600;
    color: #00698A;
}

/* .mainmenu-title > a:hover , .mainmenu-title > a:active{
    color: #000 !important;
    background: #00C3EE;
} */

.header-section .menu-search{
    padding: 6px 16px !important;
    border: 1px solid #a8a8a8 !important;
    font-size: 14px;
    color: #101010;
    font-weight: 600;
}

.header-section .input-group-text{
    background: #00698A !important;
    border: 1px solid #a8a8a8 !important;
}
.form-control:focus{
    box-shadow: none !important;
}
input::placeholder{
    color: #8b8b8b !important;
    font-weight: 100 !important;
}

.header-socialicon-section{
    margin-right: 12px;
    fill: #fff !important;
}