.trend-video-section{
    text-align: left;
}
.trend-video-tag{
    padding: 10px 20px;
    background: #E4F2EF;
    border-radius: 20px;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    text-align: center;
    color: #000000;
    width: fit-content;
    margin-right: 12px;
    display: inline-block;
}
.trend-video-header{
    font-size: 2.25em;
    text-align: left;
    padding: 3rem 0 1rem 0;
}
.trend-video-title{
    font-size: 18px;
    font-weight: 600;
}
.seefull-video-btn-section{
    text-align: center;
}
.seefull-video-btn{
    padding: 10px 25px;
    width: fit-content;
    height: 44px;
    background: #FFFFFF;
    border: 2px solid #00698A;
    border-radius: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #00698A;
}
.seefull-video-btn:hover{
    background: #88C4F0;
    color: #000;
    border: none;
}
.trend-playicon-section{
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width:70px;
    height: 70px;
    background:url(./assets/playicon.svg) no-repeat;

}