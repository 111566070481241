.sponser-title{
    font-size: 24px ;
}

.sponser-section .react-multiple-carousel__arrow--right{
    right: calc(0% + 1px);
}
.sponser-section .react-multiple-carousel__arrow--left{
    left: calc(0% + 1px);
}
.sponser-section .react-multiple-carousel__arrow{
    min-width : 25px;
    min-height: 25px;
    border-radius: 2px;
    background:rgba(0,0,0,0.8)
}

@media (min-width: 1200px){
    .sponser-section .react-multi-carousel-list{
        justify-content: center !important;
    }
}

