.breadcrumb-section a{
    font-weight: 600;
    font-size: 14px;
}
.aboutus-section{
    text-align: left;
}
.missionmsg-section{
    background: #E4F2EF;
    padding:2em;
    text-align: center;
}
.ourteam-section{
    background: #F8F9FA;
    padding: 3em 4em;
}

.card-profile-img{
    width: 150px;
    height: 150px;
    background-size: cover;
    display: inline-block;
    border-radius: 85px;
    padding: 1em;  
}
.profile-card{
    background: #fff;
    border-radius: 6px;
    padding: 1.5em 0;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}
.profilecard-name{
    font-size: 22px;
}
.profilecard-title{
    font-size: 16px;
}
.profilecard-content{
    display: inline-block;
    vertical-align: middle;
}
.socialicon-btn.clicked {
    background: #00C3EE;
}
@media (min-width: 1200px){
    .aboutus-section .col-xl-5{
        width: 47% !important;
    }
}
