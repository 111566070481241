.subscribe-section{
    background: #00C3EE;
    text-align: left;
}
.subscribe-header{
    font-family: "Montserrat-Semibold";
    font-size: 24px;
}
.subscribe-content{
    font-size: 18px;
    font-family: "Montserrat-Medium";
}
.subscribe-btn{
    padding: 10px 25px;
    width: 132px;
    height: 44px;
    background: #FFFFFF;
    border: 2px solid #00698A;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #00698A;

}
.subscribe-btn-loading{
    padding: 10px 25px;
    width: 132px;
    height: 44px;
    background: rgb(199 199 199 / 85%);
    border: 2px solid #00698A;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    color: #00698A;

}
.success-message {
    display: flex;
    align-items: center;
    /* background-color: #ffffff; */
    padding: 6px;
    border-radius: 5px;
    /* margin-top: 10px; */
    color: #07851B;
    font-weight: 600;
    justify-content: left;
    font-size: 14px;
    margin-top: 10px;
  }

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
    font-size: 1.3em;
    font-weight: bold;
    color: #fff;
  }
  .ribbon {
    --f: .3em; /* control the folded part */
    
    position: absolute;
    top: 0;
    left: -5px;
    line-height: 1.8;
    padding-inline: 2lh;
    padding-bottom: var(--f);
    border-image: conic-gradient(#0008 0 0) 51%/var(--f);
    clip-path: polygon(
      100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 
      0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
    transform: translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg);
    transform-origin: 100% 100%;
    background-color:  #033f62; /* the main color  */
  }
  @media (max-width: 992px){
    .subscribe-row{
        margin-left: 1.3em !important;
        margin-top: 1.8em !important;
    }
  }

  @media (min-width: 1281px) and (max-width: 1650px){
    .ribbon{
      font-size: 16px !important;
      left: 0px !important;
      padding-inline:1.1lh;
    }
    .subscribe-row{
      margin-left: 1em !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1280px){
    .ribbon{
      font-size: 14px !important;
      left: 0px !important;
      padding-inline:1.5lh;
    }
  }

  @media (max-width: 991px) and (min-width: 640px){
    .ribbon{
      font-size: 14px !important;
      left: 0px !important;
      padding-inline:1.2lh;
    }
  }

  @media (max-width: 639px) and (min-width: 325px){
    .ribbon{
      font-size: 12px !important;
      left: 1px !important;
      padding-inline:1.7lh;
    }
  }